import axios from "axios";

export default {
  async getData() {
    return await axios
      .get("vendor-evaluation")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getDetails(id) {
    return await axios
      .get(`vendor-evaluation/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async delete(id) {
    return await axios
      .delete(`vendor-evaluation/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getQuestion() {
    return await axios
      .get("vendor-evaluation/get-questions")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async saveData(data) {
    axios
      .post("vendor-evaluation/", data)
      .then((res) => {
        console.log("saveData", res);
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async uploadFile(data) {
    axios
      .post("vendor-evaluation/upload-file/", data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },


  async allowedAction(req) {
    axios
      .post("vendor-evaluation/action/", req)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
};
