<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding">
          <div class="d-flex align-center justify-space-between">
            <div>
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                Vendor Evaluation Details
              </h5>
            </div>

            <!-- <v-menu v-show="currentPage.actions_allowed.length > 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    elevation="0"
                    :ripple="false"
                    dark
                    class="text-capitalize btn-ls py-3 px-6 mx-2 blue"
                  >
                    Actions ▽
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(itm, i) in currentPage.actions_allowed"
                    :key="i"
                  >
                    <v-btn
                      icon
                      elevation="0"
                      :ripple="false"
                      height="28"
                      min-width="36"
                      width="80px"
                      class="my-1"
                      @click="allowedAction(itm)"
                    >
                      {{ itm }}
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu> -->
            <v-spacer></v-spacer>
            <action-button
              :actions="currentPage.actions_allowed"
              @onClick="allowedAction"
            />
            <v-btn
              @click="back"
              elevation="0"
              :ripple="false"
              dark
              height="43"
              color="blue"
              class="text-capitalize btn-ls py-3 px-6 mx-2 blue"
              >Back</v-btn
            >
          </div>
        </div>
        <v-card-text class="card-padding">
          <v-form ref="frm">
            <v-row>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1">
                  <!-- <span class="red--text"
                    >
                    <strong v-if="is_edit">* </strong>
                    </span
                  > -->
                  Ref</label
                >

                <v-text-field
                  v-model="editedItem.ref"
                  placeholder="Enter Ref"
                  :rules="[(v) => !!v || 'Ref is required']"
                  :disabled="!is_edit"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1">
                  <!-- <span class="red--text"
                    ><strong v-if="is_edit">* </strong></span
                  > -->
                  Manufacturing site</label
                >

                <v-text-field
                  v-model="editedItem.manufacturing_site"
                  :disabled="!is_edit"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Enter Manufacturing site"
                  :rules="[(v) => !!v || 'Manufacturing site is required']"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1">
                  <!-- <span class="red--text"
                    ><strong v-if="is_edit">* </strong></span
                  > -->
                  Office</label
                >

                <v-text-field
                  v-model="editedItem.office"
                  :disabled="!is_edit"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Enter Office"
                  :rules="[(v) => !!v || 'Office is required']"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1">
                  <!-- <span class="red--text"
                    ><strong v-if="is_edit">* </strong></span
                  > -->
                  Name of tech contact</label
                >

                <v-text-field
                  v-model="editedItem.name_of_tech_contact"
                  placeholder="Enter name of tech contact"
                  :rules="[(v) => !!v || 'Name of tech contact is required']"
                  :disabled="!is_edit"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1">
                  <!-- <span class="red--text"
                    ><strong v-if="is_edit">* </strong></span
                  > -->
                  Tech job title</label
                >

                <v-text-field
                  v-model="editedItem.tech_job_title"
                  placeholder="Enter Tech job title"
                  :rules="[(v) => !!v || 'Tech job title is required']"
                  :disabled="!is_edit"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1">
                  <!-- <span class="red--text"
                    ><strong v-if="is_edit">* </strong></span
                  > -->
                  Tech phone</label
                >

                <v-text-field
                  v-model="editedItem.tech_phone"
                  placeholder="Enter Tech phone"
                  :rules="[(v) => !!v || 'Tech phone is required']"
                  :disabled="!is_edit"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1">
                  <!-- <span class="red--text"
                    ><strong v-if="is_edit">* </strong></span
                  > -->
                  Tech fax</label
                >

                <v-text-field
                  v-model="editedItem.tech_fax"
                  placeholder="Enter Tech fax"
                  :rules="[(v) => !!v || 'Tech fax is required']"
                  :disabled="!is_edit"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1">
                  <!-- <span class="red--text"
                    ><strong v-if="is_edit">* </strong></span
                  > -->
                  Tech email</label
                >

                <v-text-field
                  v-model="editedItem.tech_email"
                  placeholder="Enter Tech email"
                  :rules="[(v) => !!v || 'Tech email is required']"
                  :disabled="!is_edit"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1">
                  <!-- <span class="red--text"
                    ><strong v-if="is_edit">* </strong></span
                  > -->
                  Name of sale contact</label
                >

                <v-text-field
                  v-model="editedItem.name_of_sale_contact"
                  placeholder="Enter name of sale contact"
                  :rules="[(v) => !!v || 'Name of sale contact is required']"
                  :disabled="!is_edit"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1">
                  <!-- <span class="red--text"
                    ><strong v-if="is_edit">* </strong></span
                  > -->
                  Sale job title</label
                >

                <v-text-field
                  v-model="editedItem.sale_job_title"
                  placeholder="Enter Sale job title"
                  :rules="[(v) => !!v || 'Sale job title is required']"
                  :disabled="!is_edit"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1">
                  <!-- <span class="red--text"
                    ><strong v-if="is_edit">* </strong></span
                  > -->
                  Sale phone</label
                >

                <v-text-field
                  v-model="editedItem.sale_phone"
                  placeholder="Enter Sale phone"
                  :rules="[(v) => !!v || 'Sale phone is required']"
                  :disabled="!is_edit"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1">
                  <!-- <span class="red--text"
                    ><strong v-if="is_edit">* </strong></span
                  > -->
                  Sale fax</label
                >

                <v-text-field
                  v-model="editedItem.sale_fax"
                  placeholder="Enter Sale fax"
                  :rules="[(v) => !!v || 'Sale fax is required']"
                  :disabled="!is_edit"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1">
                  <!-- <span class="red--text"
                    ><strong v-if="is_edit">* </strong></span
                  > -->
                  Sale email</label
                >

                <v-text-field
                  v-model="editedItem.sale_email"
                  placeholder="Enter Sale email"
                  :rules="[(v) => !!v || 'Sale email is required']"
                  :disabled="!is_edit"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1">
                  <!-- <span class="red--text"
                    ><strong v-if="is_edit">* </strong></span
                  > -->
                  Name of product supplied</label
                >

                <v-text-field
                  v-model="editedItem.product_name_supplied"
                  placeholder="Enter name of product supplied"
                  :rules="[
                    (v) => !!v || 'Name of product supplied is required',
                  ]"
                  :disabled="!is_edit"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1">
                  <!-- <span class="red--text"
                    ><strong v-if="is_edit">* </strong></span
                  > -->
                  Product code
                </label>

                <v-text-field
                  v-model="editedItem.product_code"
                  placeholder="Enter Product code"
                  :rules="[(v) => !!v || 'Product code is required']"
                  :disabled="!is_edit"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1">
                  <!-- <span class="red--text"
                    ><strong v-if="is_edit">* </strong></span
                  > -->
                  Country of origin</label
                >

                <v-text-field
                  v-model="editedItem.country_of_origin"
                  placeholder="Enter Country of origin"
                  :rules="[(v) => !!v || 'Country of origin is required']"
                  :disabled="!is_edit"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1">
                  <!-- <span class="red--text"
                    ><strong v-if="is_edit">* </strong></span
                  > -->
                  Other items produced</label
                >

                <v-text-field
                  v-model="editedItem.other_items_produced"
                  placeholder="Enter Other items produced"
                  :rules="[(v) => !!v || 'Other items produced is required']"
                  :disabled="!is_edit"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  persistent-hint
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <label class="text-md text-typo font-weight-bolder ms-1">
                  <!-- <span class="red--text"
                    ><strong v-if="is_edit">* </strong></span
                  > -->
                  Date</label
                >

                <v-menu
                  ref="mnu_date"
                  v-model="mnu_date"
                  :close-on-content-click="false"
                  :return-value.sync="mnu_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.created_date"
                      :disabled="true"
                      v-bind="attrs"
                      v-on="on"
                      hide-details="auto"
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        input-icon
                        mt-5
                      "
                      dense
                      flat
                      filled
                      solo
                      height="43"
                      placeholder="Date"
                      persistent-hint
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    color="green lighten-1"
                    header-color="primary"
                    v-model="date"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="mnu_date = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.mnu_date.save(editedItem.created_date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <label class="text-md text-typo font-weight-bolder ms-1 mt-3">
                  <!-- <span class="red--text"
                    ><strong v-if="is_edit">* </strong></span
                  > -->
                  Company certification (ISO9000/ ISO 14000/ HACCP/GMP
                  etc)</label
                >
              </v-col>
              <v-col cols="12" md="3">
                <v-radio-group
                  class="mt-0"
                  row
                  v-model="editedItem.company_certification"
                >
                  <v-radio
                    :disabled="!is_edit"
                    on-icon="$checkboxOn"
                    off-icon="$checkboxOff"
                    label="Yes"
                    color="green"
                    :value="true"
                  >
                  </v-radio>
                  <v-radio
                    :disabled="!is_edit"
                    on-icon="$checkboxOn"
                    off-icon="$checkboxOff"
                    label="No"
                    color="red"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row v-if="editedItem.company_certification">
              <v-col cols="4">
                <label class="text-md text-typo font-weight-bolder ms-1">
                  <!-- <span class="red--text"
                    ><strong v-if="is_edit">* </strong></span
                  > -->
                  Attach the copy of the certificate</label
                >
                <v-file-input
                  prepend-inner-icon
                  v-model="file"
                  :disabled="!is_edit"
                  hide-details="auto"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                    mt-5
                  "
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Upload file"
                  :rules="[(v) => !!v || 'File is required']"
                ></v-file-input>
              </v-col>
            </v-row>

            <!-- Question  -->
            <v-container class="px-0">
              <v-simple-table>
                <template v-slot:default>
                  <tr v-for="(item, i) in editedItem.child" :key="item.id">
                    <td>{{ item.question.question }}</td>
                    <td>
                      <v-radio-group
                        class="mt-0"
                        row
                        v-model="editedItem.child[i].qc_status"
                      >
                        <v-radio
                          :disabled="!is_edit"
                          on-icon="$checkboxOn"
                          off-icon="$checkboxOff"
                          label="Yes"
                          color="green"
                          :value="true"
                        >
                        </v-radio>
                        <v-radio
                          :disabled="!is_edit"
                          on-icon="$checkboxOn"
                          off-icon="$checkboxOff"
                          label="No"
                          color="red"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>

                      <!-- <v-checkbox
                        v-model="editedItem.child[i].qc_status"
                        label="Yes"
                        color="green"
                        value="true"
                        hide-details
                        true-value="1"
                      ></v-checkbox>
                    </td>
                    <td>
                      <v-checkbox
                        v-model="editedItem.child[i].qc_status"
                        label="No"
                        color="red"
                        hide-details
                        value="false"
                        true-value="0"
                      ></v-checkbox> -->
                    </td>
                  </tr>
                </template>
              </v-simple-table>
            </v-container>

            <v-container class="px-0">
              <v-row>
                <v-col cols="12" offset-md="8" md="4">
                  <v-btn
                    @click="cancel"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="text-capitalize btn-ls btn-primary py-3 px-6 mx-2"
                    >Cancel</v-btn
                  >

                  <v-btn
                    @click="deleteItem()"
                    v-show="
                      currentPage.permissions.includes(PERMISSIONS_ENUM.DELETE)
                    "
                    elevation="0"
                    :ripple="false"
                    height="43"
                    dark
                    color="#FF0000"
                    class="text-capitalize btn-ls btn-primary py-3 px-6 mx-2"
                    >Delete</v-btn
                  >
                  <v-btn
                    v-show="
                      currentPage.permissions.includes(PERMISSIONS_ENUM.EDIT)
                    "
                    @click="save"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    dark
                    class="
                      text-capitalize
                      btn-ls btn-primary
                      bg-success
                      py-3
                      px-6
                      mx-2
                    "
                    >Update</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            @click="dialogDelete = false"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls bg-light py-3 px-6"
            >Cancel</v-btn
          >

          <v-btn
            @click="deleteItemConfirm"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-success
              py-3
              px-6
            "
            >Ok</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { PERMISSIONS_ENUM } from "../../../../enum/permission";
import axios from "axios";
import api from "../api";
import ActionButton from "../../../../components/ActionButton.vue";
export default {
  components: { ActionButton },
  data() {
    return {
      PERMISSIONS_ENUM: PERMISSIONS_ENUM,
      file: null,
      file_name: "",
      is_edit: false,
      id: this.$route.params.id,
      dialogDelete: false,
      currentPage: this.$route.query.currentPage,

      editedItem: {
        ref: "",
        manufacturing_site: "",
        office: "",
        name_of_tech_contact: "",
        tech_job_title: "",
        tech_phone: "",
        tech_fax: "",
        tech_email: "",

        name_of_sale_contact: "",
        sale_job_title: "",
        sale_phone: "",
        sale_fax: "",
        sale_email: "",

        product_name_supplied: "",
        product_code: "",
        country_of_origin: "",
        other_items_produced: "",

        created_date: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        company_certification: false,
      },

      overlay: false,
      questions: [],
      ans: [],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mnu_date: false,
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.overlay = true;
      this.questions = await api.getQuestion();
      let data = await api.getDetails(this.$route.params.id);
      this.editedItem = data[0];
      this.editedItem.created_date = this.formatDate(
        this.editedItem.created_date
      );
      this.is_edit = this.$route.query.currentPage.permissions.includes(
        PERMISSIONS_ENUM.EDIT
      );
      this.overlay = false;
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    cancel() {
      this.$refs.frm.reset();
      this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
    },

    async deleteItem() {
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.closeDelete();
      try {
        await api.delete(this.id);
        this.showSuccessAlert(`Category Deleted.`);
      } catch (error) {
        this.showErrorAlert(error.message);
      } finally {
        this.$router.push({ name: "Vendor Evaluation" });
      }
    },
    validate() {
      this.$refs.frm.validate();
    },
    async save() {
      if (this.validate()) {
        // let array = [];
        // if (this.ans.flat().length == this.questions.length) {
        this.overlay = true;
        // this.questions.forEach((el, i) => {
        //   let status = false;
        //   if (this.ans[i] === "false") {
        //     status = false;
        //   } else {
        //     status = true;
        //   }
        //   let d = {
        //     question: el,
        //     qc_status: status,
        //   };
        //   array.push(d);
        // });
        // let req = {
        //   ...this.editedItem,
        //   child: array,
        // };

        try {
          await axios
            .patch("vendor-evaluation/" + this.id, this.editedItem)
            .then(() => {
              // if (this.editedItem.company_certification) {
              //   // File
              //   let bodyFormData = new FormData();
              //   bodyFormData.append("file", this.file, this.file.name);
              //   bodyFormData.append("id", res.data.id);
              //   api.uploadFile(bodyFormData);
              //   // File end
              // }
              this.showSuccessAlert("Data Saved");
              this.$router.push({ name: "Vendor Evaluation" });
              this.overlay = false;

              this.clear();
            })
            .catch((err) => {
              throw new Error(err.response.data.message);
            });

          // const res = await api.saveData(req);
          // console.log("re", res);
        } catch (error) {
          this.showErrorAlert(error);

          // } else {
          //   this.showWarningAlert("Please Answer All Question");
          // }
        }
      }
    },
    clear() {
      this.$refs.frm.reset();
      this.overlay = false;

      this.ans = [];
      this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
      this.mnu_date = false;
    },
    back() {
      this.$router.push({ name: "Vendor Evaluation" });
    },
    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        showConfirmButton: false,
        type: "error",
        timer: 3000,
        icon: "error",
      });
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },

    async allowedAction(item) {
      this.overlay = true;
      try {
        api.allowedAction({
          id: this.id,
          action: item,
        });

        this.overlay = false;
        this.showSuccessAlert("State updated");
      } catch (error) {
        this.showErrorAlert(error);
      } finally {
        this.$router.push({ name: "Vendor Evaluation" });
      }
    },
  },
};
</script>

<style></style>
