export const PERMISSIONS_ENUM = {
  CREATE: "CREATE",
  DELETE: "DELETE",
  EDIT: "EDIT",
};

export const PURCHASE_STATUS = {
  CONFIRM: "CONFIRMED",
  RECEIVE: "RECEIVED",
};
